<template>
  <div class="login">
    <div class="container">
      <div class="lt"></div>
      <div class="rt">
        <div class="title">{{ $t(title) }}</div>
        <div v-if="submitType !== 'reset'" class="des">{{ $t(des) }}</div>
        <div class="bd">
          <el-form
            ref="commonForm"
            :model="commonForm"
            :rules="rules"
            class="commonForm"
          >
            <el-form-item v-if="submitType === 'email'" prop="email">
              <el-input
                v-model="commonForm.email"
                :placeholder="this.$t('c10d147')"
              />
            </el-form-item>
            <el-form-item v-if="submitType === 'reset'" prop="password">
              <el-input
                v-model="commonForm.password"
                show-password
                :placeholder="this.$t('2d1a143')"
                :disabled="disabled"
              />
            </el-form-item>
            <el-form-item v-if="submitType === 'reset'" prop="confirmPassword">
              <el-input
                v-model="commonForm.confirmPassword"
                show-password
                :placeholder="this.$t('9fa64ba')"
                :disabled="disabled"
              />
            </el-form-item>

            <el-form-item v-if="!disabled">
              <el-button
                type="primary"
                class="loginButton"
                @click="changeSubmitType()"
              >
                {{ $t(buttonText) }}
              </el-button>
            </el-form-item>
          </el-form>
          <div v-if="submitType === 'pass'" class="ui-text">
            <div>{{ $t(des1) }}？</div>
            <div class="link" @click="email">{{ $t(des2) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { base64Password } from '@/utils/helper'
import API from '@/api'
import { mapActions } from 'vuex'
import { log } from '@/utils/helper'
import validator from '@/utils/validation'
// import { debounce } from 'lodash'
export default {
  name: 'Reset',
  components: {},
  data() {
    return {
      // email pass reset
      submitType: this.$route.query.type ? this.$route.query.type : 'email',
      disabled: false,
      title: '',
      des: '',
      des1: '54560f2',
      des2: '771f645',
      buttonText: '',
      commonForm: {
        email: '',
        password: '',
        confirmPassword: '',
      },

    }
  },
  computed: {
    rules() {
      return {
        email: [
          { required: true, message: this.$t('c10d147') },
          { validator: validator.email },
        ],
        password: [
          { required: true, message: this.$t('2d1a143') },
          { validator: validator.password },
        ],
        confirmPassword: [
          { required: true, message: this.$t('37fd1ce') },
          { validator: validator.password },
          {
            validator: (rule, value, callback) => {
              const str = this.$t('8baf675')
              if (value !== this.commonForm.password) {
                callback(new Error(str))
              }
              callback()
            },
          },
        ],
      }
    },
  },
  watch: {
    submitType: {
      immediate: true,
      handler(newValue) {
        const obj = {
          email: 'handleEmail',
          pass: 'handlePass',
          reset: 'handleReset',
        }
        const fun = obj[newValue]
        if (fun && this[fun]) {
          this[fun]()
        }
      },
    },
  },
  mounted() {
    // this.handleTest()
  },
  methods: {
    ...mapActions(['LoginIn']),
    handleEmail() {
      this.title = 'd0f5102'
      this.des = `92f0185`
      this.buttonText = `127e973`
    },
    handlePass() {
      this.title = '9862338'
      this.des = `${this.$t('689a915')}${this.commonForm.email}${this.$t('c93fe77')}`
      this.buttonText = `7c2a426`
    },
    handleReset() {
      this.title = '823ac8b'
      this.buttonText = `0d42603`
    },
    changeSubmitType() {
      // const type = this.submitType
      // if (type === 'email') {
      //   this.submitType = 'pass'
      // } else if (type === 'pass') {
      //   this.submitType = 'email'
      // } else if (type === 'reset') {
      //   this.submitForm()
      // }
      this.submitForm()
    },
    handleTest() {
      if (window.location.host === 'localhost:8080') {
        this.commonForm = {
          phone: '13399666602',
          // phone: '',
          account: '',
          graphical: '1234',
          short: 123465,
          password: '',
        }
      }
    },
    retrievePassword() {
      API.account
        .retrievePassword({
          email: this.commonForm.email,
          url: window.location.origin + '/',
        })
        .then((res) => {
          if (res.code === '000000') {
            this.handleRes(
              res,
              '8ba61bd'
            )
            this.submitType = 'pass'
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          log('Interface exception API.account.retrievePassword()')
        })
    },
    resetPassword() {
      const key = this.$route.query.key
      // const key = 'cea3cc8c26b6d0b6145d852e3b3f61b2'
      if (!key) {
        this.$message.error('请获取身份验证key')
        return
      }
      API.account
        .resetPassword({
          password: base64Password(this.commonForm.password),
          confirmPassword: base64Password(this.commonForm.confirmPassword),
          key: key,
        })
        .then((res) => {
          this.handleRes(res, '993082d')
          this.goLogin()
        })
        .catch(() => {
          log('Interface exception API.account.retrievePassword()')
        })
    },
    goLogin() {
      this.disabled = true
      const timeId = setTimeout(() => {
        this.$router.push('/account/login')
        clearTimeout(timeId)
      }, 3000)
    },
    handleRes(res, msg = '') {
      if (res.code === '000000') {
        this.$message.success({
          message: this.$t(msg) || res.message,
          duration: 6000,
          showClose: true,
        })
      } else {
        this.$message.error(res.message)
      }
    },
    resetForm() {
      this.$refs['commonForm'].resetFields()
    },
    reset() {
      this.resetPassword()
    },
    pass() {
      this.submitType = 'email'
      this.resetForm()
    },
    email() {
      this.retrievePassword()
    },
    submitForm() {
      this.$refs.commonForm.validate((valid) => {
        if (valid) {
          this[this.submitType]()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    userLoginByAccount() {
      const form = this.commonForm
      const data = {
        code: form.graphical,
        password: form.password,
        username: form.account,
      }
      this.handleLogin(data)
    },
    userLoginByPhone() {
      const form = this.commonForm
      const data = {
        phone: form.phone,
        code: form.graphical,
        smsCode: form.short,
      }
      this.handleLogin(data)
    },
    changeActive(index) {
      this.resetForm()
      this.activeIndex = index
      if (index === 1) {
        this.loginType = 'userLoginByPhone'
      } else {
        this.loginType = 'userLoginByAccount'
      }
    },
    goRegister() {
      this.$router.push({
        path: '/account/register',
      })
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.login {
  min-height: calc(100vh - 244px);
  padding: 100px 0;
  box-sizing: border-box;
  .container {
    display: flex;
    align-items: center;
    width: @uni-width;
    margin: 0 auto;
    // margin-top: 180px;
  }
  .lt {
    width: 802px;
    height: 458px;
    margin-right: 20px;
    background: url('~@/assets/account/login.png') center no-repeat;
    background-size: 802px 458px;
    .legalAidInfo {
      margin-top: 20px;
    }
  }
  .rt {
    .title {
      margin-bottom: 40px;
      text-align: center;
      font-size: 18px;
      color: #333333;
    }
    .des {
      height: 21px;
      margin-bottom: 30px;
      font-size: 12px;
      color: #888888;
      line-height: 21px;
    }
    .hd {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      font-size: 18px;
      color: #999999;
      white-space: nowrap;
      div {
        cursor: pointer;
      }
      div:first-child {
        margin-right: 60px;
      }
      .active {
        position: relative;
        color: @uni-text-color-select;
        &::after {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -8px;
          content: '';
          width: 100%;
          height: 2px;
          background: @uni-text-color-select;
        }
      }
    }
    .bd {
      display: flex;
      flex-direction: column;
      align-items: center;

      .commonForm {
        width: 360px;
        & /deep/ .el-form-item {
          margin-bottom: 40px;
        }
        & /deep/ .loginButton {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 360px;
          height: 40px;
          background: #00a4ff;
          border-radius: 23px;
        }
        & /deep/ .el-input__inner {
          border-radius: 23px;
        }
      }

      .ui-text {
        display: flex;
        font-size: 14px;
        color: #8f8f8f;
        .link {
          color: @uni-text-color-select;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
